import {
  ChatConversationMessageInput,
  Role,
  SendFollowUpQuestionMutation,
  SendFollowUpQuestionMutationVariables
} from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { useMutation } from "@outschool/ui-apollo";
import React, { useCallback, useState } from "react";

import { sendFollowUpQuestionMutation } from "../../../queries/ClassInquiryQueries";
import { useChatContext } from "../chatProvider";
import { ChatInputField } from "../components/ChatInputField";

export const SendFollowUpInput = () => {
  const { t } = useTranslation("client\\components\\chat\\teacherPullChat");
  const trackEvent = useTrackEvent();
  const {
    classInquiryUpdated,
    updateFollowUpConversation,
    conversation,
    classInquiry
  } = useChatContext();
  const [inputValue, setInputValue] = useState("");

  const [sendFollowUpQuestion] = useMutation<
    SendFollowUpQuestionMutation,
    SendFollowUpQuestionMutationVariables
  >(sendFollowUpQuestionMutation);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const getFollowUpConversation = useCallback(() => {
    return conversation
      .filter(message => message.mainText)
      .map(message => {
        return {
          role: String(message.sender) as Role,
          content: message.mainText
        } as ChatConversationMessageInput;
      });
  }, [conversation]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    trackEvent("teacher_pull_chat_followup_submit_button_clicked");

    if (inputValue) {
      let followUpConversation = getFollowUpConversation();
      followUpConversation.push({
        role: Role.User,
        content: inputValue
      });

      updateFollowUpConversation(inputValue);

      try {
        const { data: followUpQuestionData } = await sendFollowUpQuestion({
          variables: {
            conversation: followUpConversation,
            classInquiryUid: classInquiry?.classInquiries[0]?.uid || ""
          }
        });

        if (!!followUpQuestionData?.sendFollowUpQuestion) {
          // TODO: Add logic in classInquiryUpdated
          classInquiryUpdated();
        }
      } catch (error) {
        OsPlatform.captureError(error);
      }
    }
  };

  const inputHelperText = t(
    "Provide additional information or ask follow-up questions."
  );

  return (
    <form onSubmit={handleSubmit}>
      <ChatInputField
        inputHelperText={inputHelperText}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      />
    </form>
  );
};
