import { Button } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import React from "react";

import { useChatContext } from "../chatProvider";
import { Message } from "../components/Message";
import { Sender } from "../consts";

export const ParentMessageWithLearnerPill = ({ text }: { text: string }) => {
  const { t } = useTranslation("client\\components\\chat\\teacherPullChat");
  const { selectedLearner: { name, age } = {} } = useChatContext();

  const leanerInfo =
    name && age ? t("{{name}} ({{age}}yo)", { name, age }) : "";
  return (
    <Message text={text} sender={Sender.User}>
      <>
        {leanerInfo && (
          <Button
            disabled
            size="small"
            variant="outlined"
            sx={{ alignSelf: "start" }}
          >
            {leanerInfo}
          </Button>
        )}
      </>
    </Message>
  );
};
