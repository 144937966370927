import { HOLDOUT } from "@outschool/experiments-shared";
import { useSession } from "@outschool/ui-auth";
import { useExperiment } from "@outschool/ui-experiments";

const EXPERIMENT_NAME = "TeacherPullAndSearchCounselor";

export const useTeacherPullExperiment = (experimentName = EXPERIMENT_NAME) => {
  const { currentUserHasLoaded, isLoggedIn } = useSession();

  const { variant, trigger } = useExperiment({
    currentUserHasLoaded,
    experimentName
  });

  const triggerExperiment = () => {
    if (isLoggedIn) {
      trigger();
    }
  };

  const isTreatment = variant === "treatment" && isLoggedIn;
  const isHoldout = variant === HOLDOUT;

  return {
    isHoldout,
    isTreatment,
    triggerExperiment
  };
};
