import React from "react";

import { StateType } from "./chatProvider";

export const CHAT_FLOWS = {
  TEACHER_PULL: "teacherPull",
  CONCIERGE: "concierge"
} as const;

export type ChatFlowsType = (typeof CHAT_FLOWS)[keyof typeof CHAT_FLOWS];

export const MESSAGE_ID = {
  WELCOME: "welcome",
  WELCOME_ACTIONS: "welcomeActions",
  SEND_CLASS_REQUEST: "sendTeacherRequest",
  RETRY_SEND_CLASS_REQUEST: "retrySendTeacherRequest",
  FOLLOWUP_QUESTION: "followUpQuestion",
  HELP_ME_WITH_CURRENT_CLASS: "helpMeWithCurrentClass",
  SEND_SUPPORT_ISSUE: "sendSupportIssue",
  LOADING: "loading",
  ERROR: "error"
} as const;

// Keep this in sync with @outschool/db-queries/dist/queries/ClassInquiryQueries
export const ClassInquiryStatus = {
  Created: "Created", // when inquiry is first created in DB
  Valid: "Valid", // pass the quality check by chatGPT & defined is not spam
  Eligible: "Eligible", // pass the check by chatGPT that has enough information for us to find relevant class
  EligibleButNotShared: "EligibleButNotShared", // passed the check by chatGPT but not shared with teachers because only 3 inquiries can be shared with teachers per day
  InProgress: "InProgress", // at least 1 teacher replied
  Completed: "Completed" // After receiving 3 teachers replies
} as const;

export type MessageIdType = (typeof MESSAGE_ID)[keyof typeof MESSAGE_ID];

export enum Sender {
  Assistant = "assistant",
  System = "system",
  User = "user"
}

export type Message = {
  sender: Sender;
  render: () => React.ReactElement;
  mainText?: String; //this is for adding to open ai's question chain for following up questions
};

type FlowConfig = {
  initState: StateType;
};

type ChatFlowConfigsType = { [key in ChatFlowsType]: FlowConfig };
export const CHAT_FLOW_CONFIGS: ChatFlowConfigsType = {
  [CHAT_FLOWS.TEACHER_PULL]: {
    initState: {
      currentMessageId: MESSAGE_ID.SEND_CLASS_REQUEST,
      conversation: []
    }
  },
  [CHAT_FLOWS.CONCIERGE]: {
    initState: {
      currentMessageId: MESSAGE_ID.WELCOME,
      conversation: []
    }
  }
};
