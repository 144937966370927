import { RequestClassInquiryMutation } from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import React from "react";

import { useTeacherPullExperiment } from "../search/useTeacherPullExperiment";
import { ACTION_TYPES, chatReducer } from "./chatReducer";
import { ErrorMessage } from "./components/ErrorMessage";
import { SelectedLearner } from "./components/LearnerSelection";
import { Message as MessageComponent } from "./components/Message";
import {
  ClassInquiryStatus,
  MESSAGE_ID,
  Message,
  MessageIdType,
  Sender
} from "./consts";
import { ParentMessageWithLearnerPill } from "./teacherPullChat/ParentResponseClassRequestMessage";
import { SendClassRecommendationMessage } from "./teacherPullChat/SendClassRecommendationMessage";
import { SendClassRequestMessage } from "./teacherPullChat/SendClassRequestMessage";
import { Welcome } from "./Welcome";

export type ClassInquiryType = NonNullable<
  RequestClassInquiryMutation["requestClassInquiry"]
>;

export type StateType = {
  currentMessageId: MessageIdType;
  conversation: Message[];
  classInquiry?: ClassInquiryType;
  selectedLearner?: SelectedLearner;
};

export type ChatContextType = StateType & {
  sendClassInquirySubmit: (_: string) => void;
  classInquiryCreated: (_: ClassInquiryType) => void;
  updateFollowUpConversation: (_: string) => void;
  classInquiryUpdated: () => void;
  findANewClassOnClick: () => void;
  helpMeWithACurrentClassOnClick: () => void;
  sendAiGuideSupportIssueSubmit: (_: string) => void;
  showErrorMessage: (_a: string, _b: string) => void;
  updateSelectedLearner: (_: SelectedLearner) => void;
  setIsModalOpen?: (_: boolean) => void;
};

const CONTEXT_DEFAULT_VALUE: ChatContextType = {
  currentMessageId: MESSAGE_ID.WELCOME,
  conversation: [],
  sendClassInquirySubmit: () => {},
  classInquiryCreated: () => {},
  updateFollowUpConversation: () => {},
  classInquiryUpdated: () => {},
  findANewClassOnClick: () => {},
  helpMeWithACurrentClassOnClick: () => {},
  sendAiGuideSupportIssueSubmit: () => {},
  updateSelectedLearner: () => {},
  showErrorMessage: () => {}
};

export const INIT_STATE: StateType = {
  currentMessageId: MESSAGE_ID.WELCOME,
  conversation: []
};

const ChatContext = React.createContext<ChatContextType>(CONTEXT_DEFAULT_VALUE);

type ChatProviderProps = {
  setIsModalOpen?: (_: boolean) => void;
  children?: React.ReactNode;
  initState?: StateType;
};

const canRecommendAnActivity = (classInquiryStatus: string) =>
  classInquiryStatus === ClassInquiryStatus.Eligible ||
  classInquiryStatus === ClassInquiryStatus.EligibleButNotShared;
export const ChatProvider: React.FC<
  React.PropsWithChildren<ChatProviderProps>
> = ({ setIsModalOpen, children, initState = INIT_STATE }) => {
  const { t } = useTranslation("client\\components\\chat");
  const trackEvent = useTrackEvent();
  const { isTreatment: isTreatmentV2_5 } =
    useTeacherPullExperiment("GuideV2-5");

  const [
    { currentMessageId, conversation, classInquiry, selectedLearner },
    dispatch
  ] = React.useReducer(chatReducer, initState);

  const updateSelectedLearner = (learner: SelectedLearner) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_LEARNER,
      payload: {
        learner
      }
    });
  };

  const classInquiryCreated = (classInquiry: ClassInquiryType) => {
    // currently can only create 1 inquiry at the time
    if (
      classInquiry.classInquiries[0]?.status &&
      canRecommendAnActivity(classInquiry.classInquiries[0]?.status)
    ) {
      dispatch({
        type: ACTION_TYPES.CLASS_INQUIRY_CREATED,
        payload: {
          nextMessageId:
            isTreatmentV2_5 && !!classInquiry.followUpQuestion
              ? MESSAGE_ID.FOLLOWUP_QUESTION
              : MESSAGE_ID.WELCOME_ACTIONS,
          classInquiry,
          messages:
            isTreatmentV2_5 && !!classInquiry.followUpQuestion
              ? [
                  {
                    sender: Sender.System,
                    render: () => (
                      <SendClassRecommendationMessage
                        classInquiryData={classInquiry}
                      />
                    )
                  },
                  {
                    sender: Sender.Assistant,
                    render: () => (
                      <MessageComponent
                        text={classInquiry.followUpQuestion || ""}
                        sender={Sender.System}
                      />
                    ),
                    mainText: classInquiry.followUpQuestion
                  }
                ]
              : [
                  {
                    sender: Sender.System,
                    render: () => (
                      <SendClassRecommendationMessage
                        classInquiryData={classInquiry}
                      />
                    )
                  }
                ]
        }
      });
    } else {
      dispatch({
        type: ACTION_TYPES.GO_TO_NEXT_MESSAGE,
        payload: {
          nextMessageId: MESSAGE_ID.RETRY_SEND_CLASS_REQUEST,
          messages: [
            {
              sender: Sender.System,
              render: () => (
                <ErrorMessage
                  text={t(
                    "It looks like we need a little more information to help you. Please add any additional details and try again."
                  )}
                />
              )
            }
          ]
        }
      });
      trackEvent("teacher_pull_chat_class_inquiry_no_recommendation");
    }

    trackEvent("teacher_pull_chat_class_inquiry_created");
  };
  const findANewClassOnClick = () => {
    if (currentMessageId === MESSAGE_ID.WELCOME) {
      dispatch({
        type: ACTION_TYPES.GO_TO_NEXT_MESSAGE,
        payload: {
          nextMessageId: MESSAGE_ID.SEND_CLASS_REQUEST,
          messages: [
            {
              sender: Sender.System,
              render: () => <Welcome />
            },
            {
              sender: Sender.User,
              render: () => (
                <MessageComponent
                  text={t`Help me find a new class`}
                  sender={Sender.User}
                />
              )
            }
          ]
        }
      });
    } else {
      dispatch({
        type: ACTION_TYPES.GO_TO_NEXT_MESSAGE,
        payload: {
          nextMessageId: MESSAGE_ID.SEND_CLASS_REQUEST,
          messages: [
            {
              sender: Sender.User,
              render: () => (
                <MessageComponent
                  text={t`Help me find a new class`}
                  sender={Sender.User}
                />
              )
            }
          ]
        }
      });
    }

    trackEvent("ai_guide_find_a_new_class_button_clicked");
  };

  const helpMeWithACurrentClassOnClick = () => {
    dispatch({
      type: ACTION_TYPES.GO_TO_NEXT_MESSAGE,
      payload: {
        nextMessageId: MESSAGE_ID.HELP_ME_WITH_CURRENT_CLASS,
        messages: [
          {
            sender: Sender.User,
            render: () => <Welcome />
          },
          {
            sender: Sender.System,
            render: () => (
              <MessageComponent
                text={t`Help me with a current class`}
                sender={Sender.User}
              />
            )
          }
        ]
      }
    });
    trackEvent("ai_guide_help_with_current_class_button_clicked");
  };

  const sendClassInquirySubmit = (requestText: string) => {
    if (currentMessageId === MESSAGE_ID.RETRY_SEND_CLASS_REQUEST) {
      dispatch({
        type: ACTION_TYPES.GO_TO_NEXT_MESSAGE,
        payload: {
          nextMessageId: MESSAGE_ID.LOADING,
          messages: [
            {
              sender: Sender.User,
              render: () => (
                <ParentMessageWithLearnerPill
                  text={requestText}
                ></ParentMessageWithLearnerPill>
              )
            }
          ]
        }
      });
    } else {
      dispatch({
        type: ACTION_TYPES.GO_TO_NEXT_MESSAGE,
        payload: {
          nextMessageId: MESSAGE_ID.LOADING,
          messages: [
            {
              sender: Sender.System,
              render: () => <SendClassRequestMessage />
            },
            {
              sender: Sender.User,
              render: () => (
                <ParentMessageWithLearnerPill
                  text={requestText}
                ></ParentMessageWithLearnerPill>
              )
            }
          ]
        }
      });
    }
  };

  const updateFollowUpConversation = (inputText: string) => {
    dispatch({
      type: ACTION_TYPES.GO_TO_NEXT_MESSAGE,
      payload: {
        nextMessageId: MESSAGE_ID.LOADING,
        messages: [
          {
            sender: Sender.User,
            render: () => <ParentMessageWithLearnerPill text={inputText} />,
            mainText: inputText
          }
        ]
      }
    });
  };

  const classInquiryUpdated = () => {
    // TODO: Show class recommendation and if there's another followup question show that too then navigate to MESSAGE_ID.FOLLOWUP_QUESTION
  };

  const sendAiGuideSupportIssueSubmit = (userMessage: string) => {
    dispatch({
      type: ACTION_TYPES.GO_TO_NEXT_MESSAGE,
      payload: {
        nextMessageId: MESSAGE_ID.SEND_SUPPORT_ISSUE,
        messages: [
          {
            sender: Sender.User,
            render: () => (
              <MessageComponent text={userMessage} sender={Sender.User} />
            )
          },
          {
            sender: Sender.System,
            render: () => (
              <MessageComponent
                text={t`We got your message! Our support team may contact you to help resolve this issue.`}
                sender={Sender.System}
              />
            )
          }
        ]
      }
    });
  };

  const showErrorMessage = (userMessage: string, errorMessage: string) => {
    dispatch({
      type: ACTION_TYPES.GO_TO_NEXT_MESSAGE,
      payload: {
        nextMessageId: MESSAGE_ID.WELCOME_ACTIONS,
        messages: [
          {
            sender: Sender.User,
            render: () => (
              <MessageComponent text={userMessage} sender={Sender.User} />
            )
          },
          {
            sender: Sender.System,
            render: () => <ErrorMessage text={errorMessage} />
          }
        ]
      }
    });
  };

  return (
    <ChatContext.Provider
      value={{
        currentMessageId,
        conversation,
        sendClassInquirySubmit,
        classInquiryCreated,
        updateFollowUpConversation,
        classInquiryUpdated,
        classInquiry,
        showErrorMessage,
        setIsModalOpen,
        helpMeWithACurrentClassOnClick,
        sendAiGuideSupportIssueSubmit,
        findANewClassOnClick,
        updateSelectedLearner,
        selectedLearner
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  return React.useContext(ChatContext);
};
